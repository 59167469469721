import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>Your input has been received successfully and will be reviewed by a vehicle shipping agent immediately. An auto transport professional will reach out to you shortly!</p>
        </div>
      </div>
    </section>
  </Layout>
)
